<template>
    <div v-if="(podcasts && show)" class="podcastPage">
        <div class="header2">
            <div class="roundButton">
                
            </div>
            <div class="headerContent">
                <div v-html="show.timeSlot" class="timeSlot"/>
                <div class="showTitle">{{ show.title }}</div>
            </div>
        
            <div class="roundButton">
                <router-link :to="{name: 'Show', params:{slug: this.slug}}">
                    <TheIcon icon="down" :outline="false" :background="false"/>
                </router-link>
            </div>
        </div>

        <div class="listContainer">
            <div v-for="pod in podcasts" :key="pod.id" class="simpleContainer">
                <div class="roundButton">
                    <a v-on:click="play(pod, show)" href="#">
                        <TheIcon icon="play" :outline="false" :background="false"/>
                    </a>
                </div>
                <div class="podcastData">
                    <div class="podcastTitle">{{ pod.title }}</div>
                    <div class="timeSlot">{{ new Date(pod.publicated_at).toLocaleDateString() }}</div>
                    <div class="showDescription">{{ pod.description }}</div>
                </div>
            </div>
        </div>
  </div>
  <div v-else class="loadingContainer"><TheLoading /></div>  
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import TheIcon from '@/components/TheIcon'
import TheLoading from '@/components/TheLoading'
export default {
  name: 'Podcast',
  components: {
    TheIcon,
    TheLoading
  },
  data(){
    return{
      podcasts:null
    }
  },
  props:{
    slug:{
      type: String,
      required: true
    }
  },
  computed: {
    show(){
      if(this.$shows){
          if(this.$shows.find( show => show.slug === this.slug)){
             return this.$shows.find( show => show.slug === this.slug)
          
          }else{
             return null
          }
      }
      else{
        return null
      }
    },
  },
  mounted(){
    if(!this.$podcasts[this.slug]){
      axios.defaults.headers['Pragma'] = 'no-cache';
      axios.defaults.headers['Cache-Control'] = 'no-cache';
      axios
        .get("/api/stations/radiogramma/podcasts/?show__slug="+this.slug)
        .then(response => {
          if(response.data){
            this.$podcasts[this.slug]=response.data.slice()
            this.podcasts=this.$podcasts[this.slug]
          }else{
            this.$router.push({name:'NotFound', params:{error: 'Wrong slug'}})
          }
        })
        .catch(err => {
          this.$router.push({name:'NotFound', params:{error: err.message}})
        })
    }else{
      if(this.$podcasts[this.slug]){
        this.podcasts=this.$podcasts[this.slug]
      }else{
        this.$router.push({name:'NotFound', params:{error: 'Wrong slug'}})
      }
    }
  },
    
  
  methods:{
    play(e,a){
        this.$player = {
            //autoPlay: true,
            playerMeta:{
                isPodcast: true,
                showTitle: a.title,
                showSlug:  a.slug,
                showTimeSlot:  a.timeSlot,
                showIcon: a.icon,
                podcastTitle:  e.title,
                podcastDesc:   e.description,
                podcastUpload: e.upload,
                podcastId: e.id
            },
            playerUrl: e.url
            
        }
    }
  }
}
</script>
